/* global window, document, storyblok */
import React, { Component } from "react"
import SbEditable from "storyblok-react"
import StoryblokClient from "storyblok-js-client"
import { startsWith, endsWith } from "lodash"

import Components from "../components"
import Layout from "../layouts"

function loadStoryblokBridge(cb) {
    let script = document.createElement("script")
    script.type = "text/javascript"
    script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`
    script.id = "storyblokBridge"
    script.onload = cb
    document.getElementsByTagName("head")[0].appendChild(script)
}

const getParam = function (val) {
    let result = ""
    let tmp = []

    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=")
            if (tmp[0] === val) {
                result = decodeURIComponent(tmp[1])
            }
        })

    switch (result) {
        case "/":
        case "":
        case "hult/global":
            return "hult/home"
        case "hult/execed-global":
            return "hult/en/executive-education"
        default:
            return result
    }
}

const getPath = function (fullSlug, realPath) {
    let entryPath = realPath || fullSlug
    if (!endsWith(entryPath, "/")) entryPath += "/"
    if (!startsWith(entryPath, "/")) return `/${entryPath}`
    return entryPath
}

class StoryblokEntry extends Component {
    constructor(props) {
        super(props)

        this.sbClient = new StoryblokClient({
            accessToken: process.env.GATSBY_SB_TOKEN,
        })

        this.state = { story: null }
    }

    componentDidMount() {
        loadStoryblokBridge(() => {
            this.initStoryblokEvents()
        })
    }


    loadStory() {
        this.sbClient
            .get(`cdn/stories/${getParam("path")}`, {
                version: "draft",
                resolve_relations: "efSet.efset_test,s22_page.footer,s22_page.popup,s23c_page.footer"
            })
            .then((res) => {
                this.setState({ story: res.data.story })
            })
    }

    initStoryblokEvents() {
        const { StoryblokBridge, location } = window
        const sb = new StoryblokBridge({
            resolveRelations: ["efSet.efset_test", "s22_page.footer", "s22_page.popup", "s23c_page.footer"],
        })
        this.loadStory()

        sb.on(["change", "published"], () => {
            this.loadStory()
        })

        sb.on("input", (payload) => {
            if (this.state.story && payload.story.id === this.state.story.id) {
                this.setState({ story: payload.story })
            }
        })

        sb.pingEditor(() => {
            if (sb.inEditor) {
                sb.enterEditmode()
            }
        })
    }

    static getStoryPath(fullSlug) {
        if (fullSlug === "home") {
            return "/"
        }
        return `/${fullSlug}`
    }

    render() {
        const { story } = this.state

        if (story === null) {
            return <div></div>
        }

        return (
            <Layout>
                {Components(story.content.component) && (
                    <SbEditable content={story.content}>
                        {React.createElement(Components(story.content.component), {
                            key: story.content._uid,
                            blok: story.content,
                            global: global.content,
                            path: getPath(story.full_slug, story.path),
                            story: story
                        })}
                    </SbEditable>
                )}
            </Layout>
        )
    }
}

export default StoryblokEntry
